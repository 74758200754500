"use client";

// import { fetchGoogleRating } from "actions/crawler";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { useGoogleRating } from "@/hooks/useGoogleRating";
// import useSWR from "swr";

// const key =
// "https://maps.googleapis.com/maps/api/place/details/json?&place_id=&key=&fields=rating";

function ReadMoreReviewsSection() {
  const t = useTranslations("Index");
  const ggRating = useGoogleRating();

  // const ggRating = useSWR(key, () => fetchGoogleRating());

  return (
    <section
      className=" font-degular-display-medium flex w-full xl:max-w-screen-xl 
      flex-col px-[40px] 2xl:px-0 xl:pt-[78px] pb-[88px] md:space-y-[32px]"
    >
      <p className="h1">{t("what_our_customers_are_saying")}</p>
      <div
        className=" flex flex-col md:flex-row items-start md:items-center 
        justify-start mt-[16px] md:mt-[32px] md:space-x-10"
      >
        <Image
          alt="*****"
          src="/assets/images/stars.png"
          width={244}
          height={40}
          quality={100}
        />
        <div
          className="mt-[10px] md:mt-0 mb-[32px] md:mb-0 flex space-x-[32px] 
        md:space-x-[35px]"
        >
          <span className="h5">{ggRating}</span>
          <span className="h5">2259 {t("reviews")}</span>
        </div>
      </div>

      <Link
        href="https://www.trustindex.io/reviews/travelthru.com"
        target="_blank"
        className="w-fit"
      >
        <button
          className=" px-[32px] bg-black hover:opacity-80 rounded-[10px]
                    font-dm_sans font-bold text-white h-[40px] md:h-[52px] text-[12px]
                    md:text-[16px] underline"
        >
          {t("read_more_reviews")}
        </button>
      </Link>
    </section>
  );
}

export default ReadMoreReviewsSection;
